<template>
    <div class="Cys">
        <el-form ref="formRef" status-icon :model="form" :rules="rules" label-width="100px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="类型" prop="type">
                        <el-select :ref="el=>refMap.set('type',el)" v-model="form.type" clearable placeholder="请选择类型" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'type')}">
                            <el-option v-for="item in comboSelect({comboId:'type'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="承运商名称" prop="name">
                        <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入承运商名称" maxlength="50" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="联系人" prop="contacts">
                        <el-input @input="e => form.contacts = validForbid(e)" v-model="form.contacts" placeholder="请输入联系人" maxlength="25" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="联系电话" prop="telephone">
                        <el-input @input="e => form.telephone = validForbid(e)" v-model="form.telephone" placeholder="请输入联系电话" maxlength="13" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="登录账号" prop="code">
                        <el-input @input="e => form.code = validForbid(e)" v-model="form.code" placeholder="请输入登录账号" maxlength="60" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="登录密码" prop="pwd">
                        <el-input @input="e => form.pwd = validForbid(e)" v-model="form.pwd" type="password" placeholder="请输入登录密码" maxlength="32" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="联系地址" prop="address">
                        <el-input @input="e => form.address = validForbid(e)" v-model="form.address" placeholder="请输入联系地址" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="合作日期" prop="date">
                        <el-date-picker v-model="form.date" placeholder="请选择合作日期" :disabled="disabled"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="类别" prop="joinType">
                        <el-select :ref="el=>refMap.set('joinType',el)" v-model="form.joinType" clearable placeholder="请选择类别" style="width: 100%" :disabled="disabled" @change="(val)=>{selectOnChange(val,'joinType')}">
                            <el-option v-for="item in comboSelect({comboId:'joinType'})" :key="item.value" :label="item.label" :value="item.value"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input @input="e => form.remark = validForbid(e)" type="textarea" v-model="form.remark" placeholder="请输入备注" maxlength="122" :disabled="disabled" clearable/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row v-show="!addStatus">
                <el-col :span="12">
                    <el-form-item label="是否停用">
                        <span>{{form.flag==0?'启用':'禁用'}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="创建时间">
                        <span>{{form.time}}</span>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <div v-show="showUpload" style="margin-top: 20px">
            <el-row>
                <el-col :span="2">合作协议</el-col>
                <el-col :span="10">
                    <Upload :="buildUploadInitParams({uploadType:'hzxy'})" :ref="el=>refMap.set('hzxy',el)"></Upload>
                </el-col>
                <el-col :span="2">身份证人像面</el-col>
                <el-col :span="10">
                    <Upload :="buildUploadInitParams({uploadType:'sfzFront'})" :ref="el=>refMap.set('sfzFront',el)"></Upload>
                </el-col>
            </el-row>

            <el-row style="margin-top: 20px">
                <el-col :span="2">身份证国徽面</el-col>
                <el-col :span="10">
                    <Upload :="buildUploadInitParams({uploadType:'sfzBack'})" :ref="el=>refMap.set('sfzBack',el)"></Upload>
                </el-col>
                <el-col :span="2" v-show="form.type!=0">营业执照</el-col>
                <el-col :span="10" v-show="form.type!=0">
                    <Upload :="buildUploadInitParams({uploadType:'yyzz'})" :ref="el=>refMap.set('yyzz',el)"></Upload>
                </el-col>
            </el-row>
        </div>
    </div>
</template>


<script>
    import CysCardHelper from "./CysCardHelper.js";
    export default CysCardHelper;
</script>

<style scoped>
    .Cys{
        width: 100%;
    }
</style>
